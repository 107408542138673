import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { SearchResult } from '@airwallet/shared-models/search';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { SearchService } from 'src/app/services/search/search-service';
import { AccountState } from '@airwallet/shared-models/details';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from '@airwallet/shared-models/order';
import * as Search from '@airwallet/shared-models/search';
import { LoggedInDashboardUser, DashboardUser } from '@dashboard_models/dashboard-user';
import { UserPaymentMethod } from '@airwallet/shared-models/user-management';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';
import { RefundModalComponent } from '../../misc/refund-modal/refund-modal.component';
import { NgIf, NgFor, AsyncPipe, SlicePipe } from '@angular/common';
import { LoadingComponent } from '../../loading/loading.component';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { ControlledUser } from '@airwallet/shared-models/controlled-user';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-search-category-result',
    templateUrl: './search-category-result.component.html',
    styleUrls: ['./search-category-result.component.scss', '../search.component.scss'],
    standalone: true,
    imports: [LoadingComponent, NgIf, NgFor, RefundModalComponent, CustomModalComponent, AsyncPipe, SlicePipe, TranslateModule]
})
export class SearchCategoryResultComponent implements OnInit {
    @Input() categoryResult: SearchResult[keyof SearchResult];
    @Input() categoryKey: string;
    @Input() isMobile: boolean;
    @Input() searchService: SearchService;
    @Input() slices: number[];
    @Input() user: DashboardUser;
    @Input() rates: Record<string, number>;

    isOperator$: Observable<boolean> = this.authService.isOperator;
    refundReason: Reason;
    otherReason: string;
    placeholderText: string;
    transaction: Search.Transaction;
    transactionIndex: number;
    showSmallLoadingIndicator = false;
    phoneNumber = '';
    showLoadingIndicator = true;
    routeSub: Subscription;
    constructor(
        public translate: TranslateService,
        public helperService: HelperService,
        private router: Router,
        private authService: AuthService,
        private modalService: NgbModal,
        private LocalStorageService: LocalStorageService,
        private cdr: ChangeDetectorRef
    ) {}

    async ngOnInit(): Promise<void> {
        this.user = this.helperService.getUser();
        this.rates = (await this.helperService.getCurrencyConversionRates()).rates;
    }

    async navigate(cat: string, item: unknown) {
        interface RouteType {
            path: string;
            params?: object;
        }
        let route: RouteType = { path: '' };
        route = await this.searchService.getPath(cat, item, this.user.uid);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([route.path], { queryParams: route.params });
        });
    }

    getStatusString(status: AccountState): string {
        return this.translate.instant(`customers.${status}`);
    }

    getTerms(couponDescription: string): string {
        return this.helperService.parseTranslationArray(couponDescription);
    }

    getDate(timestamp: number): string {
        return this.helperService.getLocalizedDate(timestamp);
    }

    getDateTime(timestamp: number): string {
        return `${this.helperService.getLocalizedDate(timestamp)} - ${this.helperService.getLocalizedTime(timestamp)}`;
    }

    roundPerform(num: number | null): string {
        return num ? String(`${num < 0 ? '' : '+'}${this.helperService.roundToTwoDecimals(num * 100)}%`) : '';
    }

    appendStrings(arr: string[]): string {
        let str = '';
        arr.forEach((value, i) => {
            str = str + `${value}${i === arr.length - 1 ? '' : ', '}`;
        });
        return str;
    }

    openRefundModal(modal: any, transaction: Search.Transaction, index: number) {
        this.transaction = transaction;
        this.transactionIndex = index;
        this.openModal(modal);
        this.refundReason = Reason.requested_by_customer;
        this.otherReason = '';
        this.phoneNumber = transaction.phone_number;
    }

    openRefundDetailsModal(modal: any, transaction: Search.Transaction) {
        this.transaction = transaction;
        this.openModal(modal);
    }

    openModal(modal: any) {
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title'
        };
        const modalRef: NgbModalRef = this.modalService.open(modal, modalOptions);

        modalRef.result.then(
            () => {
                // on close
            },
            () => {
                // on error/dismiss
            }
        );
    }

    getUserName(): string {
        const controlledUser: ControlledUser | null = this.LocalStorageService.getItem('controlled_user') as ControlledUser;
        if (controlledUser) {
            return 'Airwallet';
        } else {
            return (this.LocalStorageService.getItem('loggedInUser') as LoggedInDashboardUser).name;
        }
    }

    refundReasonCorrector(reason: Reason): string {
        if (reason === 'duplicate') {
            return 'Duplicate';
        }
        if (reason === 'fraudulent') {
            return 'Fraudulent';
        }
        if (reason === 'requested_by_customer') {
            return 'Requested by customer';
        }
        if (reason === 'other') {
            return 'Other';
        }
    }

    updateTransaction(transaction: Search.Transaction) {
        this.categoryResult[this.transactionIndex] = transaction;
    }

    convertCardNumber(pm: UserPaymentMethod) {
        if (pm.type === 'visa' || pm.type === 'mastercard') {
            return `• • • • ${pm.last4}`;
        }
    }
}
