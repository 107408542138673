<div class="result-wrapper">
    <loading-indicator></loading-indicator>
    <table>
        <!-- HEADERS (TH) FOR ALL VALUES -->
        <tr>
            <!-- Name and ID -->
            <ng-container *ngIf="!['contracts', 'transactions', 'settlements'].includes(categoryKey)">
                <th>{{ "search." + (categoryKey === "terminals" ? categoryKey + ".serial_number" : (categoryKey + ".name").replace("_", " ")) | translate }}</th>
            </ng-container>

            <ng-container *ngIf="['contracts', 'settlements'].includes(categoryKey)">
                <th>{{ "search." + categoryKey + ".id" | translate }}</th>
            </ng-container>
            <!-------Location name--------->

            <ng-container *ngIf="['devices', 'terminals'].includes(categoryKey)">
                <th>{{ "search.common.location" | translate }}</th>
            </ng-container>

            <!-- Customer name -->
            <ng-container *ngIf="['devices', 'terminals'].includes(categoryKey)">
                <th *ngIf="!isMobile && (isOperator$ | async)">{{ "search.common.customer" | translate }}</th>
            </ng-container>

            <!-- Customers -->
            <ng-container *ngIf="categoryKey === 'customers'">
                <th>{{ "search.customers.status" | translate }}</th>
                <th *ngIf="!isMobile" class="align-right">{{ "customers.total" | translate }}</th>
                <th *ngIf="!isMobile" class="align-right">{{ "search.customers.my_share" | translate }}</th>
                <th *ngIf="!isMobile" class="align-right">{{ "search.customers.customer_share" | translate }}</th>
            </ng-container>

            <!-- Coupons -->
            @if (categoryKey === "coupons") {
                <ng-container>
                    <th>{{ "coupon.terms" | translate }}</th>
                    <th class="align-right">{{ "coupon.entries" | translate }}</th>
                    @if (!isMobile) {
                        <th>{{ "coupon.distribute_type" | translate }}</th>
                    }
                </ng-container>
            }

            <!-- Devices -->
            <ng-container *ngIf="categoryKey === 'devices'">
                <th class="align-right">{{ "search.locations.last_30_days" | translate }}</th>
                <th class="align-right" *ngIf="!isMobile">{{ "misc.price" | translate }}</th>
                <th class="align-right" *ngIf="!isMobile">{{ "search.devices.last_used" | translate }}</th>
            </ng-container>

            <!-- Locations -->
            <ng-container *ngIf="categoryKey === 'locations'">
                <th *ngIf="!isMobile" class="align-right">{{ "search.locations.daily_starts" | translate }}</th>
                <th class="align-right">{{ "search.locations.last_30_days" | translate }}</th>
            </ng-container>

            <!-- Terminals -->
            <ng-container *ngIf="categoryKey === 'terminals'">
                <th *ngIf="!(isOperator$ | async)" class="align-right">{{ "search.locations.last_30_days" | translate }}</th>
                <th *ngIf="isOperator$ | async" class="align-right">{{ "customers.status" | translate }}</th>
            </ng-container>

            <!-- Contracts -->
            <ng-container *ngIf="categoryKey === 'contracts'">
                <th class="more-width">{{ "search.common.location" | translate }}</th>
                <th class="align-right less-width">{{ "search.contracts.users" | translate }}</th>
                <th class="align-right">{{ "search.contracts.revenue_target" | translate }}</th>
                <th class="align-right">{{ "search.contracts.revenue" | translate }}</th>
                <th class="align-right">{{ "search.contracts.billable_amount" | translate }}</th>
                <th class="align-right">{{ "search.contracts.performance" | translate }}</th>
            </ng-container>

            <!-- Settlements -->
            <ng-container *ngIf="categoryKey === 'settlements'">
                <th>{{ "search.common.date" | translate }}</th>
                <th class="align-right">{{ "search.settlements.payout_amount" | translate }}</th>
            </ng-container>

            <!-- Transactions -->
            <ng-container *ngIf="categoryKey === 'transactions'">
                <th class="more-more-width">{{ "search.transactions.id" | translate }}</th>
                <th class="more-width">{{ "search.transactions.date" | translate }}</th>
                <th class="align-right">{{ "search.transactions.amount" | translate }}</th>
                <th class="more-width">{{ "search.transactions.location" | translate }}</th>
                <th>{{ "search.transactions.device" | translate }}</th>
                <th class="align-right" style="direction: ltr">{{ "search.transactions.phone_number" | translate }}</th>
                <th class="">{{ "search.transactions.refund" | translate }}</th>
            </ng-container>

            <!-- Starts/day -->
            <ng-container *ngIf="['contracts', 'devices'].includes(categoryKey)">
                <th *ngIf="!isMobile">{{ "search.common.starts_pr_day" | translate }}</th>
            </ng-container>

            <!-- Phone no -->

            <ng-container *ngIf="categoryKey === 'users'">
                <th class="align-right" style="direction: ltr; width: 14%">{{ "search.transactions.phone_number" | translate }}</th>
                <th style="width: 9%">{{ "users.platform" | translate }}</th>
                <th class="align-right more-width">{{ "users.payment_method" | translate }}</th>
                <th class="align-right">{{ "users.total_spend" | translate }}</th>
                <th class="align-right">{{ "users.payments" | translate }}</th>
                <th class="align-right">{{ "users.refunds" | translate }}</th>
                <th>{{ "users.created" | translate }}</th>
            </ng-container>
        </tr>

        <!-- VALUES FOR TH -->

        <tr class="tr-values" (click)="navigate(categoryKey, category)" *ngFor="let category of categoryResult | slice: slices[0] : slices[1]; let i = index">
            <!-- First TD for each category -->

            <ng-container *ngIf="category.name">
                <td>
                    <div class="td-inner-div" [title]="category.name.replace('_', ' ')" [innerHTML]="searchService.highlightText(category.name.replace('_', ' '))"></div>
                </td>
            </ng-container>

            <ng-container *ngIf="category.serial">
                <td>
                    <div class="td-inner-div" [title]="category.serial" [innerHTML]="searchService.highlightText(category.serial)"></div>
                </td>
            </ng-container>

            <ng-container *ngIf="category.id && categoryKey !== 'users' && categoryKey !== 'coupons'">
                <td>
                    <div [className]="onButton ? 'td-inner-div no-bg' : 'td-inner-div'" [title]="category.id" [innerHTML]="searchService.highlightText(category.id)"></div>
                </td>
            </ng-container>

            <!-- ---- -->

            <!-- Location name & customer name -->
            <ng-container *ngIf="['devices', 'terminals'].includes(categoryKey)">
                <td>
                    <div class="td-inner-div" [title]="category.location_name ? category.location_name : category.name" [innerHTML]="searchService.highlightText(category.location_name ? category.location_name : category.name)"></div>
                </td>
                <td *ngIf="!isMobile && (isOperator$ | async) && ['devices', 'terminals'].includes(categoryKey)">
                    <div
                        class="td-inner-div"
                        [title]="category.customer_name === 'My account' ? ('search.common.my_account' | translate) : category.customer_name"
                        [innerHTML]="searchService.highlightText(category.customer_name === 'My account' ? ('search.common.my_account' | translate) : category.customer_name)"
                    ></div>
                </td>
            </ng-container>

            <!-- Customers  -->
            <ng-container *ngIf="categoryKey === 'customers'">
                <td>
                    <div class="td-inner-div" [title]="getStatusString(category.status)">
                        <div
                            class="status-wrapper"
                            [style]="
                                category.status === 'complete'
                                    ? 'color: #67C2AC'
                                    : category.status === 'inactive_devices'
                                      ? 'color: #D81E5B'
                                      : category.status === 'pending'
                                        ? 'color:transparent linear-gradient(90deg, var(--secondary-color-dark) 0%, #0150B7 100%)'
                                        : category.status === 'restricted_soon'
                                          ? 'color: #FC9124'
                                          : 'color: #D81E5B'
                            "
                        >
                            <img
                                class="status-icon"
                                [src]="
                                    category.status === 'complete'
                                        ? '../../../assets/icons/check-circle.svg'
                                        : category.status === 'inactive_devices'
                                          ? '../../../assets/icons/cross.svg'
                                          : category.status === 'pending'
                                            ? '../../../assets/icons/pending.svg'
                                            : category.status === 'restricted_soon'
                                              ? '../../../assets/icons/warning.svg'
                                              : '../../../assets/icons/cross.svg'
                                "
                            />{{ getStatusString(category.status) }}
                        </div>
                    </div>
                </td>
                <!-- Total -->
                <td *ngIf="!isMobile">
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.balance / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(category.balance / 100, 2, 2, category.currency))"
                    ></div>
                </td>
                <!-- My share -->
                <td *ngIf="!isMobile">
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.my_share / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(category.my_share / 100, 2, 2, category.currency))"
                    ></div>
                </td>
                <!-- Customer share -->
                <td *ngIf="!isMobile">
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.customer_share / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(category.customer_share / 100, 2, 2, category.currency))"
                    ></div>
                </td>
            </ng-container>

            <!-- Coupons -->
            <ng-container *ngIf="categoryKey === 'coupons'">
                <td>
                    <div class="td-inner-div" [title]="getTerms(category.description)" [innerHTML]="searchService.highlightText(getTerms(category.description))"></div>
                </td>
                <td *ngIf="!isMobile">
                    <div
                        class="td-inner-div align-right"
                        [title]="category.entries ? (category.entries.users.limit ? category.entries.users.count + '/' + category.entries.users.limit : category.entries.users.count) : ''"
                        [innerHTML]="searchService.highlightText(category.entries ? (category.entries.users.limit ? category.entries.users.count + '/' + category.entries.users.limit : category.entries.users.count) : '')"
                    ></div>
                </td>
                <td *ngIf="!isMobile">
                    <div class="td-inner-div align-right" [title]="'coupon.dis_type.' + category.distribute_type | translate" [innerHTML]="searchService.highlightText('coupon.dis_type.' + category.distribute_type | translate)"></div>
                </td>
            </ng-container>

            <!--Devices-->
            <ng-container *ngIf="categoryKey === 'devices'">
                <!-- Last 30 days -->
                <td>
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.revenue / 100 ? category.revenue / 100 : 0, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(category.revenue / 100 ? category.revenue / 100 : 0, 2, 2, category.currency))"
                    ></div>
                </td>
                <!-- Price -->
                <td *ngIf="!isMobile">
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.formatCurrency(category.price, category.currency) + helperService.getDeviceUnitPriceTranslated(category.price_unit, category.pulse_increment)"
                        [innerHTML]="searchService.highlightText(helperService.formatCurrency(category.price, category.currency) + helperService.getDeviceUnitPriceTranslated(category.price_unit, category.pulse_increment))"
                    ></div>
                </td>
                <td *ngIf="!isMobile">
                    <div class="td-inner-div align-right" [title]="getDate(category.last_used)" [innerHTML]="searchService.highlightText(getDate(category.last_used))"></div>
                </td>
            </ng-container>

            <!-- Locations -->
            <ng-container *ngIf="categoryKey === 'locations'">
                <td *ngIf="!isMobile">
                    <div class="td-inner-div align-right" [title]="helperService.localizeNumber(category.daily_starts)" [innerHTML]="searchService.highlightText(helperService.localizeNumber(category.daily_starts))"></div>
                </td>
                <!-- Last 30 days -->
                <td>
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.revenue / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(category.revenue / 100, 2, 2, category.currency))"
                    ></div>
                </td>
            </ng-container>

            <!-- Terminals -->
            <ng-container *ngIf="categoryKey === 'terminals'">
                <td>
                    <!-- On normal cusstomers show payments count -->
                    <div
                        *ngIf="!(isOperator$ | async)"
                        class="td-inner-div align-right"
                        [title]="category.payments + ' ' + translate.instant('search.terminals.payments')"
                        [innerHTML]="searchService.highlightText(category.payments + ' ' + translate.instant('search.terminals.payments'))"
                    ></div>
                    <!-- On Operators show status from anton health -->
                    <div class="td-inner-div align-right" style="display: flex; align-items: center">
                        <span *ngIf="isOperator$ | async" [className]="'dot ' + (category.status === 'active' ? 'green' : 'red')"></span>
                    </div>
                </td>
            </ng-container>

            <!-- Contracts -->
            <ng-container *ngIf="categoryKey === 'contracts'">
                <td class="more-width">
                    <div class="td-inner-div" [title]="appendStrings(category.location_names)" [innerHTML]="searchService.highlightText(appendStrings(category.location_names))"></div>
                </td>
                <td>
                    <div class="td-inner-div align-right" [title]="category.users" [innerHTML]="searchService.highlightText(category.users)"></div>
                </td>
                <td>
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.revenue_target / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(category.revenue_target / 100, 2, 2, category.currency))"
                    ></div>
                </td>
                <td>
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.revenue / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(category.revenue / 100, 2, 2, category.currency))"
                    ></div>
                </td>
                <td>
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.billable_amount / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(category.billable_amount / 100, 2, 2, category.currency))"
                    ></div>
                </td>
                <td>
                    <div
                        class="td-inner-div align-right performance"
                        [title]="roundPerform(category.performance)"
                        [style]="category.performance < 0 ? 'color: #D81E5B;' : 'color: #29E1B4;'"
                        [innerHTML]="searchService.highlightText(roundPerform(category.performance))"
                    ></div>
                </td>
            </ng-container>

            <!-- Settlements -->
            <ng-container *ngIf="categoryKey === 'settlements'">
                <td>
                    <div class="td-inner-div" [title]="category.payout_id" [innerHTML]="searchService.highlightText(category.payout_id)"></div>
                </td>
                <td>
                    <div class="td-inner-div" [title]="getDate(category.created)" [innerHTML]="searchService.highlightText(getDate(category.created))"></div>
                </td>
                <td>
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.amount / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(category.amount / 100, 2, 2, category.currency))"
                    ></div>
                </td>
            </ng-container>

            <!-- Transactions -->
            <ng-container *ngIf="categoryKey === 'transactions'">
                <td>
                    <div [className]="onButton ? 'td-inner-div no-bg' : 'td-inner-div'" [title]="getDateTime(category.timestamp)" [innerHTML]="searchService.highlightText(getDateTime(category.timestamp))"></div>
                </td>
                <td>
                    <div
                        [className]="onButton ? 'td-inner-div no-bg align-right' : 'td-inner-div align-right'"
                        [title]="helperService.localizeNumberWithCurrency(category.amount / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(category.amount / 100, 2, 2, category.currency))"
                    ></div>
                </td>
                <td>
                    <div [className]="onButton ? 'td-inner-div no-bg' : 'td-inner-div'" [title]="category.location_name" [innerHTML]="searchService.highlightText(category.location_name)"></div>
                </td>
                <td>
                    <div [className]="onButton ? 'td-inner-div no-bg' : 'td-inner-div'" [title]="category.device_name" [innerHTML]="searchService.highlightText(category.device_name)"></div>
                </td>
                <td>
                    <div
                        [className]="onButton ? 'td-inner-div no-bg align-right' : 'td-inner-div align-right'"
                        style="direction: ltr; border-radius: 0 3px 3px 0"
                        [title]="helperService.formatterPhoneNumber(category.phone_number)"
                        [innerHTML]="searchService.highlightText(helperService.formatterPhoneNumber(category.phone_number))"
                    ></div>
                </td>
                <td (mouseenter)="onButton = true" (mouseleave)="onButton = false">
                    <button
                        type="button"
                        [className]="category.refund ? 'refunded td-inner-div' : 'btn btn-secondary btn-tiny btn-hoverable'"
                        [class.disabled]="category.refund"
                        (click)="category.refund ? openRefundDetailsModal(refundReasonModal, category) : openRefundModal(refundModal, category, i); $event.stopPropagation()"
                    >
                        {{ showSmallLoadingIndicator && transactionIndex === i ? "" : category.refund ? "Refunded" : "Refund" }}
                        <loading-indicator *ngIf="transactionIndex === i && showSmallLoadingIndicator" [size]="'btn-tiny-refund'" [color]="'white'"> </loading-indicator>
                    </button>
                </td>
            </ng-container>

            <!-- Starts/day (Doesnt include location category) -->
            <ng-container *ngIf="['devices', 'contracts'].includes(categoryKey)">
                <td *ngIf="!isMobile">
                    <div class="td-inner-div align-right" [title]="category.daily_starts" [innerHTML]="searchService.highlightText(helperService.localizeNumber(category.daily_starts))"></div>
                </td>
            </ng-container>

            <!-- Users -->

            <ng-container *ngIf="categoryKey === 'users'">
                <td>
                    <div
                        class="td-inner-div align-right"
                        style="direction: ltr"
                        [title]="category.phone_number_call_code + ' ' + category.phone_number"
                        [innerHTML]="searchService.highlightText(category.phone_number_call_code + ' ' + category.phone_number, true)"
                    ></div>
                </td>
                <td>
                    <div
                        class="td-inner-div"
                        [title]="translate.instant(category.platform ? ('users.platforms.' + category.platform | translate) : ('misc.none' | translate))"
                        [innerHTML]="searchService.highlightText(category.platform ? ('users.platforms.' + category.platform | translate) : ('misc.none' | translate))"
                    ></div>
                </td>
                <td>
                    <div
                        class="td-inner-div"
                        [title]="category.user_role === 'test' ? ('users.user.test' | translate) : category.payment_method && category.payment_method.last4 ? convertCardNumber(category.payment_method) : ('misc.none' | translate)"
                    >
                        <div *ngIf="category.payment_method && category.user_role !== 'test'" style="display: flex; gap: 0.75rem; justify-content: flex-end">
                            <span>
                                {{
                                    category.user_role === "test"
                                        ? ("users.user.test" | translate)
                                        : category.payment_method && category.payment_method.last4
                                          ? convertCardNumber(category.payment_method)
                                          : category.payment_method && category.payment_method.type
                                            ? ("users.payment_methods.type." + category.payment_method.type.toLowerCase() | translate)
                                            : ("misc.none" | translate)
                                }}
                            </span>
                            @if (category.payment_method.type !== "no_payment_type") {
                                <img
                                    style="width: 19px; aspect-ratio: 1"
                                    [title]="'users.payment_methods.type.' + category.payment_method.type.toLowerCase() | translate"
                                    [src]="'../../../assets/icons/payment-methods/' + category.payment_method.type.toLowerCase() + '.svg'"
                                />
                            }
                        </div>
                        <div *ngIf="category.user_role === 'test'" style="display: flex; gap: 0.75rem; justify-content: flex-end">
                            <span>{{ "users.user.test" | translate }}</span>
                            <img style="width: 19px; aspect-ratio: 1" [src]="'../../../assets/icons/payment-methods/test.svg'" />
                        </div>
                        <div *ngIf="!category.payment_method && category.user_role !== 'test'" style="display: flex; gap: 0.75rem; justify-content: flex-end">
                            {{ "misc.none" | translate }}
                        </div>
                    </div>
                </td>
                <td>
                    <div class="td-inner-div align-right" [title]="category.payments" [innerHTML]="searchService.highlightText(category.payments)"></div>
                </td>
                <td>
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.currency ? category.total_spend[category.currency] : 0 / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrencyAndConversionForRecord(category.total_spend, user.settings.currency, rates))"
                    ></div>
                </td>
                <td>
                    <div
                        class="td-inner-div align-right"
                        [title]="helperService.localizeNumberWithCurrency(category.currency ? category.total_spend[category.currency] : 0 / 100, 2, 2, category.currency)"
                        [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrencyAndConversionForRecord(category.refunds, user.settings.currency, rates))"
                    ></div>
                </td>
                <td>
                    <div class="td-inner-div align-right" [title]="getDate(category.created)" [innerHTML]="searchService.highlightText(getDate(category.created))"></div>
                </td>
            </ng-container>
        </tr>
    </table>

    <ng-template #refundModal let-modal>
        <app-refund-modal [phoneNumberOrUid]="phoneNumber" [transaction]="transaction" (onStateChange)="updateTransaction($event)"></app-refund-modal>
    </ng-template>

    <ng-template #refundReasonModal let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>{{ "transactions.refund_tooltip_title" | translate }}</h4>
            </ng-container>
            <ng-container modal-body>
                <p>{{ "transactions.refund_by_user" | translate }}: {{ transaction.user_name }}</p>
                <p>{{ "transactions.refund_tooltip_reason" | translate }}: {{ refundReasonCorrector(transaction.refund_reason) }}</p>
                <p *ngIf="transaction.refund_reason === 'other'">{{ "transactions.refund_tooltip_other_reason" | translate }}: {{ transaction.refund_details }}</p>
            </ng-container>
            <ng-container modal-footer> </ng-container>
        </app-custom-modal>
    </ng-template>
</div>
