export interface UserOrder {
    id_from_administration?: string;
    note?: string;
    payment_id: string;
    py_id?: string;
    operator_py_id?: string;
    po_id?: string; // note: can be Stripe auto payout id with format "po_...." or AW manual payouts with format "po...."
    operator_po_id?: string; // note: can be Stripe auto payout id with format "po_...." or AW manual payouts with format "po...."
    refund_po_id?: string; // created in refund endpoint
    operator_refund_po_id?: string; // created in refund endpoint
    amount: number;
    operator_amount?: number; // only present if the operator got a cut in the transaction to the sub customer
    currency: string; // Three-letter ISO 4217 currency code, in lowercase. Must be a supported currency in Stripe (https://stripe.com/docs/currencies).
    customer_id: string; // Firebase and not STRIPE!
    location_id: string;
    location_name: string;
    device_id: string;
    device_name: string;
    user_id: string;
    timestamp: number;
    no_charge: boolean; // previous called 'test', but this is also going to be used is device mode is 'demo' or future 'free_vend' on user
    phone_number?: string;
    refund: boolean; // Associated with the one below
    refund_status?: Status; // only present on refunded orders
    transfer_group_id?: string;
    serial: string;
    terminal_serial_number?: string; // to be used for Anton terminals
    product_type: string;
    machine_serial_number?: string;
    cycle_price?: number;
    refund_timestamp?: number;
    minutes?: number;
    payment_method_fingerprint?: string;
    refund_reason?: Reason;
    refund_details?: string; // only if reason is "other" this can be applied
    user_name?: string; //only set with refund
    payment_method_type?: string; // From app
    transaction_type: TransactionType; // From app
    reconciled?: number; // unix timestamp, set by event queue to initial value of 0, if reconciled = 0, then it is not reconciled, if reconciled > 0, then it is reconciled.
    refund_reconciled?: number; // unix timestamp, set on refund to initial value of 0, if refund_reconciled = 0, then it is not reconciled, if refund_reconciled > 0, then it is reconciled.
    fee?: {
        amount: number;
        type: FeeType;
    };
    operator_fee?: {
        amount: number;
        type: FeeType;
    };
    coupon_key?: string;
    original_amount?: number; // is applied if a coupon is used
    contract_id?: string;
    location_custom_id?: string;
    vat_number?: string;
    wallet_balance?: number; // if payment type is wallet, and it is the wallet balance after the transaction
    pyr_id?: string;
    operator_pyr_id?: string;

    original_operator_balance_info?: {
        total: number;
        minimum_revenue: number;
    };
}
// to be removed on 1/6 -2025
// export interface Order {
//     id_from_administration?: string;
//     note?: string;
//     payment_id: string;
//     py_id?: string;
//     operator_py_id?: string;
//     po_id?: string; // note: can be Stripe auto payout id with format "po_...." or AW manual payouts with format "po...."
//     operator_po_id?: string; // note: can be Stripe auto payout id with format "po_...." or AW manual payouts with format "po...."
//     refund_po_id?: string; // created in refund endpoint
//     operator_refund_po_id?: string; // created in refund endpoint
//     amount: number;
//     operator_amount?: number; // only present if the operator got a cut in the transaction to the sub customer
//     currency: string; // Three-letter ISO 4217 currency code, in lowercase. Must be a supported currency in Stripe (https://stripe.com/docs/currencies).
//     customer_id: string; // Firebase and not STRIPE!
//     location_id: string;
//     location_name: string;
//     device_id: string;
//     device_name: string;
//     user_id: string;
//     timestamp: number;
//     no_charge: boolean; // previous called 'test', but this is also going to be used is device mode is 'demo' or future 'free_vend' on user
//     phone_number?: string;
//     refund: boolean; // Associated with the one below
//     refund_status?: Status; // only present on refunded orders
//     transfer_group_id?: string;
//     serial: string;
//     terminal_serial_number?: string; // to be used for Anton terminals
//     product_type: string;
//     machine_serial_number?: string;
//     cycle_price?: number;
//     refund_timestamp?: number;
//     minutes?: number;
//     payment_method_fingerprint?: string;
//     refund_reason?: Reason;
//     refund_details?: string; // only if reason is "other" this can be applied
//     user_name?: string; //only set with refund
//     payment_method_type?: string; // From app
//     transaction_type: TransactionType; // From app
//     reconciled?: number; // unix timestamp, set by event queue to initial value of 0, if reconciled = 0, then it is not reconciled, if reconciled > 0, then it is reconciled.
//     refund_reconciled?: number; // unix timestamp, set on refund to initial value of 0, if refund_reconciled = 0, then it is not reconciled, if refund_reconciled > 0, then it is reconciled.
//     fee?: {
//         amount: number;
//         type: FeeType;
//     };
//     operator_fee?: {
//         amount: number;
//         type: FeeType;
//     };
//     coupon_key?: string;
//     original_amount?: number; // is applied if a coupon is used
//     contract_id?: string;
//     location_custom_id?: string;
//     vat_number?: string;
//     wallet_balance?: number; // if payment type is wallet, and it is the wallet balance after the transaction
//     pyr_id?: string;
//     operator_pyr_id?: string;

//     // filter keys
//     location_id_timestamp?: string | null; // legacy filter key
//     location_id_device_id_timestamp?: string | null; // legacy filter key

//     // new filter keys. parts separated by underscore
//     //all
//     t?: string; //all timestamp just use timestamp
//     lt?: string; //all location timestamp
//     ldt?: string; //all location device timestamp
//     // completed
//     ct?: string | null; //completed timestamp, to be deleted on refund
//     clt?: string | null; //completed location timestamp, to be deleted on refund
//     cldt?: string | null; //completed location device timestamp, to be deleted on refund
//     // refunded
//     rt?: string | null; //refunded timestamp, only present on refunded orders
//     rlt?: string | null; //refunded location timestamp, only present on refunded orders
//     rldt?: string | null; //refunded location device timestamp, only present on refunded orders
// }

export interface FireOrder {
    push_id: string; // from realtime db
    currency: string; // Three-letter ISO 4217 currency code, in lowercase. Must be a supported currency in Stripe (https://stripe.com/docs/currencies).
    amount: number;
    original_amount: number | null; // is applied if a coupon is used
    payment_id: string;
    timestamp: number;
    owner_uid: string; // Firebase and not STRIPE!
    operator_owner_uid: string;

    id_from_administration: string | null;
    note: string | null;

    py_id: string | null;
    po_id: string | null; // note: can be Stripe auto payout id with format "po_...." or AW manual payouts with format "po...."

    refund: boolean; // Associated with the one below
    refund_po_id: string | null; // created in refund endpoint
    refund_timestamp: number | null;
    refund_status: Status | null; // only present on refunded orders
    refund_reason: Reason | null;
    user_name: string | null; //only set with refund
    pyr_id: string | null;

    operator_py_id: string | null;
    operator_po_id: string | null; // note: can be Stripe auto payout id with format "po_...." or AW manual payouts with format "po...."
    operator_refund_po_id: string | null; // created in refund endpoint
    operator_amount: number | null; // only present if the operator got a cut in the transaction to the sub customer
    operator_pyr_id: string | null;

    location_id: string;
    location_name: string;
    location_custom_id: string | null;
    device_id: string;
    device_name: string;
    serial: string;
    product_type: string;
    terminal_serial_number: string | null; // to be used for Anton terminals
    machine_serial_number: string | null;

    user_id: string;
    phone_number: string | null;
    payment_method_fingerprint: string | null;

    reconciled: number | null; // unix timestamp, set by event queue to initial value of 0, if reconciled = 0, then it is not reconciled, if reconciled > 0, then it is reconciled.
    refund_reconciled: number | null; // unix timestamp, set on refund to initial value of 0, if refund_reconciled = 0, then it is not reconciled, if refund_reconciled > 0, then it is reconciled.

    no_charge: boolean; // previous called 'test', but this is also going to be used is device mode is 'demo' or future 'free_vend' on user
    transfer_group_id: string | null;
    cycle_price: number | null;
    minutes: number | null;
    refund_details?: string; // only if reason is "other" this can be applied
    payment_method_type?: string; // From app
    transaction_type: TransactionType; // From app

    fee: {
        amount: number;
        type: FeeType;
    } | null;
    operator_fee: {
        amount: number;
        type: FeeType;
    } | null;
    coupon_key: string | null;
    contract_id: string | null;
    vat_number: string | null;
    wallet_balance: number | null; // if payment type is wallet, and it is the wallet balance after the transaction

    original_operator_balance_info?: {
        total: number;
        minimum_revenue: number;
    };
}

export interface PayoutOrder {
    original_amount: number;
    py: string;
    transfered_amount: number;
    payment_id: string;
    timestamp: number;
    amount: number;
    currency: string;
    customerName: string;
    location_name: string;
    location_id: string;
    device_name: string;
    serial: string;
    phone_number?: string;
    refund: boolean;
    po_id?: string;
    coupon_key?: string;
    refund_po_id?: string;
    operator_po_id?: string;
    operator_refund_po_id?: string;
    id_from_administration?: string;
    note?: string;
    fee: {
        amount: number;
    };
    user_id: string;
    owner_uid: string;
}

export enum FeeType {
    LIFE = 'life',
    MIN_FEE = 'minFee',
    PERCENT_FEE = 'percentFee'
}

export enum Reason {
    duplicate = 'duplicate',
    fraudulent = 'fraudulent',
    requested_by_customer = 'requested_by_customer',
    other = 'other'
}

export enum Status {
    succeeded = 'succeeded',
    pending = 'pending',
    failed = 'failed',
    requires_action = 'requires_action',
    canceled = 'canceled'
}

export interface RefundParams {
    paymentId: string;
    docId?: string;
    customerUid?: string;
    phoneNumberOrUid?: string; // phone number or app user uid
    userName: string;
    refundReason: Reason;
    otherReason?: string;
}

export interface ApiOrder {
    id_from_administration?: string;
    transaction_id: string; // also called payment_id
    timestamp: number;
    refund: boolean; //if refunded = true, else = false
    amount: number;
    currency: string;
    location_name: string;
    location_id: string;
    device_name: string;
    device_id: string;
    phone_number: string;
    serial: string;
    product_type: string;
    machine_serial?: string;
    cycle_price?: number;
    key?: string; // order key
}

export interface ExportedOrder {
    po_id?: string; // note: can be Stripe auto payout id with format "po_...." or AW manual payouts with format "aw_po_...."
    operator_po_id?: string; // note: can be Stripe auto payout id with format "po_...." or AW manual payouts with format "aw_po_...."
    refund_po_id?: string; // created in refund endpoint
    operator_refund_po_id?: string; // created in refund endpoint
    id_from_administration?: string;
    timestamp: number;
    refund: boolean; //refunded -> complete
    amount: number;
    currency: string;
    location_name: string;
    device_name: string;
    location_id: string;
    device_id: string;
    note?: string;
    location_id_device_id_timestamp?: string;
    location_id_timestamp?: string;
    phone_number?: string;
    serial?: string;
    product_type: string;
    uid: string;
    transaction_id: string; // in db called payment_id
    key: string;
    user_id: string;
    refund_reason?: Reason;
    refund_details?: string; // only if reason is "other" this can be applied
    user_name?: string; //only set with refund
    refund_status?: Status; // only present on refunded orders
}

export interface TransactionsOutputInfo {
    limit: number;
    returned_count: number;
    total_count: number;
    cursor?: string; // the cursor is a combined string that is build from the key from the next order in the range with the total number of orders the range query found appended.
    details?: string; // optional message for the info object, used if no results were found.
}

export interface TransactionsOutput {
    orders: Record<string, ApiOrder>;
    info: TransactionsOutputInfo;
}

export interface ExcelTemplate {
    transaction_id: string;
    date: string;
    amount: number;
    original_amount: number;
    coupon_name: string;
    currency: string;
    device: string;
    serial_no: string;
    phone_no: string;
    status: string;
    payout_id: string;
    refund_payout_id: string;
    operator_payout_id: string;
    operator_refund_payout_id: string;
    credit_user_id: string;
    comments: string;
}

export enum TransactionType {
    WALLET_PAYMENT = 'wallet_payment',
    PAYMENT = 'payment',
    COUPON = 'coupon',
    CREDIT = 'credit',
    TEST = 'test'
}

export interface ApiOrderV2 {
    key: string;
    transaction_id: string; // on order => payment_id
    timestamp: number;
    user_id: string;
    location_id: string;
    device_id: string;
    device_name: string;
    product_type: string; // is not enum, because it is not that on orders
    currency: string;
    amount: number; // what was paid
    original_amount: number; // what was paid before any discounts
    id_from_administration: string | null;
    cycle_price: number | null;
    transaction_type: TransactionType;
    coupon_id: string | null;
    serial: string;
    vat_number: string | null;
    machine_serial: string | null;
    location_custom_id: string | null;
    contract_id: string | null;
    wallet_balance: number | null; // if payment type is wallet, and it is the wallet balance after the transaction
    platform: 'app' | 'terminal';
    payment_method_fingerprint: string | null;
}

export interface ApiRefundOrderV2 {
    user_id: string;
    location_id: string;
    device_id: string;
    device_name: string;
    product_type: string; // is not enum, because it is not that on orders
    currency: string;
    amount: number; // what was paid
    original_amount: number; // what was paid before any discounts
    id_from_administration: string | null;
    cycle_price: number | null;
    coupon_id: string | null;
    serial: string;
    vat_number: string | null;
    machine_serial: string | null;
    location_custom_id: string | null;
    contract_id: string | null;
    wallet_balance: number | null; // if payment type is wallet, and it is the wallet balance after the transaction
    source_transaction_key: string; // source transaction key
    source_transaction_id: string; // source transaction id
    source_transaction_timestamp: number;
    source_transaction_type: TransactionType;
    refund_timestamp: number;
    refund_reason: Reason;
    refund_details: string | null; // only if reason is "other" this can be applied
    platform: 'app' | 'terminal';
    payment_method_fingerprint: string | null;
}

export interface ApiTopup {
    key: string;
    transaction_id: string;
    timestamp: number;
    user_id: string;
    currency: string;
    amount: number;
    type: 'TOPUP' | 'WITHDRAWAL';
    withdrawal_fee: number | null;
}
