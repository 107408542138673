import { Injectable } from '@angular/core';
import { HttpService, RequestTypes } from '../helper/http.service';
import { TransactionsQueryResult } from '@airwallet/shared-models/transactions';
import { RefundParams } from '@airwallet/shared-models/order';
import { FilterSortParams } from '@airwallet/shared-models/search-params/FilterSortParams';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Injectable({
    providedIn: 'root'
})
export class TransactionService {
    constructor(
        private httpService: HttpService,
        private db: AngularFireDatabase
    ) {}
    async getTransactions(data: FilterSortParams, onBehalf: string): Promise<TransactionsQueryResult> {
        return this.httpService.dynamicHttp(`api_transactions/get?params=${JSON.stringify(data)}`, RequestTypes.GET, { onBehalf });
    }
    async refund(req: RefundParams, onBehalf?: string): Promise<any> {
        return this.httpService.dynamicHttp(`api_transactions/refund`, RequestTypes.PUT, { body: req, onBehalf });
    }
    async getSubcustomerNames(): Promise<{ value: string; label: string }[]> {
        return this.httpService.dynamicHttp(`api_operator/names_for_filter`, RequestTypes.GET);
    }
    async getLocationNames(uid: string): Promise<{ value: string; label: string }[]> {
        return this.httpService.dynamicHttp(`api_location/names_for_filter`, RequestTypes.GET, { onBehalf: uid });
    }
    async getDeviceNames(uid: string, locationId: string): Promise<{ value: string; label: string }[]> {
        return this.httpService.dynamicHttp(`api_device/names_for_filter`, RequestTypes.GET, { onBehalf: uid, params: { locationId } });
    }
}
